.row-content {
  padding: 50px 0;
  /* border-bottom: 1px ridge; */
  min-height: 200px;
}

.row-content-small {
  padding: 20px 0;
}

.row-content-nopadding {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.row-content-padding {
  padding-top: 0px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.row-content-padding-small {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.row-content-white {
  padding: 80px 0;
  background-color: white;
  min-height: 200px;
}

.row-content-grey {
  padding: 80px 0;
  background-color: rgb(249, 249, 249);
  min-height: 200px;
}

.site-footer {
  background-image: linear-gradient(rgb(248, 248, 248), rgb(248, 248, 248));
  padding: 40px 0;
}

.navbar-dark {
  background-color: rgb(53, 54, 58);
}

.btn-outline-color {
  color: black;
  padding: 10px;
}

.dot,
.dot.selected {
  transition: transition(opacity, 0.25s, ease-in);
  opacity: opacity(0.3);
  box-shadow: 1px 1px 2px rgba(#000, 0.9);
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}

.dot:hover {
  transition: transition(opacity, 0.25s, ease-in);
  opacity: opacity(0.3);
  box-shadow: 1px 1px 2px rgba(#000, 0.9);
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
  opacity: opacity(1);
}

td {
  text-align: center;
}

tr:nth-child(even) {
  background-color: #eee;
}

th[scope="col"] {
  text-align: center;
  background-color: #696969;
  color: #fff;
}

table {
  border-collapse: collapse;
  border: 2px solid rgb(200, 200, 200);
  letter-spacing: 1px;
}
