/* @import url("https://fonts.googleapis.com/css2?family=Avenir:wght@531&display=swap"); */

@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url("./fonts/Avenir.ttc") format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Avenir", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Graphik", "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
